<template>
    <div class="d-flex content-center min-vh-100">
        <BContainer>
            <BRow class="justify-content-center">
                <BCol md="8">
                    <BCard class="text-left" footer-tag="footer" footer-bg-variant="light">
                        <div slot="header">
                            <strong class="alert-danger">{{userId}}</strong> 비밀번호 재설정
                            <div class="card-header-actions">
                                <small class="text-muted"> 비밀번호가 초기화 되어 재설정 합니다.</small>
                            </div>
                        </div>


                        <BRow >
                            <BCol>
                                <BFormInput class="mt-3" v-model="currPwd"  type="password" placeholder="현재 비밀번호를 입력하세요"/>
                                <BFormInput class="mt-3" v-model="newPwd"  :state="validPwdState"  type="password" placeholder="비밀번호는 영문.숫자.특수문자를 포함해야 하며 최소8자 이상입니다" :disabled="!isValidUser"/>
                                <BFormInput class="mt-2" v-model="rePwd" :state="validPwdConfirm" type="password" placeholder="비밀번호를 다시 입력해주새요" :disabled="!isValidUser"/>

                            </BCol>
                        </BRow>

                        <BRow class="mt-3" align-h="between">
                            <BCol>
                                <BButton to="/login">로그인</BButton>
                            </BCol>
                            <BCol class="text-right">
                                <BButton  variant="danger" @click="setUserPwd" :disabled="!isValidUser || !isPwdChecked">비밀번호 변경</BButton>
                            </BCol>
                        </BRow>


                    </BCard>


                </BCol>
            </BRow>
        </BContainer>


    </div>
</template>

<script>
    import {
        apiCall,
        cloneVar,
        alertError,
        alertWarn,
        modalSuccess,
        alertConfirm,
        checkBisNo,
        commify,
        toastSync
    } from '@/common/utils';

    // import DaumPostcode from 'vuejs-daum-postcode';
    import moment from "moment";

    export default {
        name: 'PasswordReset',
        props: ['userId'],
        components: {
            // DaumPostcode
        },
        data () {
            return {
                newPwd: '',
                currPwd: '',
                rePwd: '',
                isValidUser: false,
                isPwdChecked: false,
                pwdRegexp: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/, // 최소8자 영문,숫자,특수문자 조합
            }
        },
        async created(){

            try{
                console.log("-------- created: PasswordReset From ----- userId: " + this.userId );
                console.log("-------- created: PasswordReset From ----- userId: " + JSON.stringify(this.$route.params) );
                if(this.userId) this.isValidUser = true;



            }catch(err){
                console.log(err);
                await alertWarn( this.$bvModal, 'ERROR: '+err.message, '에러' );
                return;
            }

        },
        beforeMount() {

        },
        mounted(){
            console.log("-------- mounted: PasswordReset -----  " );
            console.log("-------- mounted: PasswordReset loginId: " + this.loginId );

        },

        computed: {

            validPwdState(){
                let ret = false;
                // const p2 = this.newPwdConfirm;
                if( this.newPwd.length > 7 ){
                    if( this.pwdRegexp.test( this.newPwd )) ret = true;
                }
                return ret;
            },
            validPwdConfirm(){
                let ret = false;
                const p1 = this.newPwd;
                const p2 = this.rePwd;
                if( p2.length > 7 && p1 === p2 ){
                    ret = true;
                    this.isPwdChecked = true;
                }else{
                    this.isPwdChecked = false;
                }
                return ret;
            },
        },
        methods: {

            async setUserPwd(){
                console.log( "setUserPwd ---> ");
                try{

                    const param = {
                        userId: this.userId,
                        currPwd: this.currPwd,
                        newPwd : this.newPwd,
                        rePwd : this.rePwd
                    };
                    const r = await apiCall('post', `/api/auth/pwd-change-on-login`, param);
                    if(r.result){
                        await modalSuccess(this.$bvModal, "비밀번호가 변경 되었습니다", "Success");
                        await this.$router.push({ path: '/login'  });
                    }else{
                        await alertWarn( this.$bvModal, '비밀번호 변경 실패: '+ r.message, 'Error:' + r.code );
                        // alert('관리자에게 문의하시기 바랍니다');
                    }

                }catch(err){
                    console.log( err );
                    await alertWarn( this.$bvModal, 'ERROR: ' + err.message, '에러' );
                }
            },

        }

    }
</script>
